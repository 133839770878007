import React from "react"
import styled from "styled-components"

import Toolbar from "./toolbar"
import Sidebar from "./sidebar"
import "./layout.css"

const SiteContainer = styled.div`
  display: flex;
  flex-flow: column;
  min-height: 100vh;
`

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;

  ${props => (props.isMobile ? "top: 70px" : "top: 0")}
`

const Content = styled.main`
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  flex: 1;
  flex-flow: column;
  padding: 10px 15px;
`

export default class Layout extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isSidebarOpen: null,
      isMobile: null,
    }
  }

  componentDidMount() {
    this.checkForMobileView()

    window.addEventListener("resize", this.checkForMobileView)
  }

  checkForMobileView = () => {
    return window.innerWidth < 768
      ? this.setState(state => ({ isMobile: true, isSidebarOpen: false }))
      : this.setState(state => ({ isMobile: false, isSidebarOpen: true }))
  }

  toggleSidebar = () => {
    this.setState(state => ({ isSidebarOpen: !this.state.isSidebarOpen }))
  }

  render() {
    return (
      <SiteContainer>
        <Toolbar
          isSidebarOpen={this.state.isSidebarOpen}
          isMobile={this.state.isMobile}
          toggleSidebar={this.toggleSidebar}
        />
        <ContentContainer isMobile={this.state.isMobile}>
          <Sidebar
            isSidebarOpen={this.state.isSidebarOpen}
            isMobile={this.state.isMobile}
          />
          <Content>{this.props.children}</Content>
        </ContentContainer>
      </SiteContainer>
    )
  }
}
