import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const Navigation = styled.nav`
  display: flex;
  flex-flow: column;
  font-size: 24px;
  text-align: center;

  a {
    color: white;
    display: inline-block;
    font-family: "Montserrat", sans-serif;
    padding: 10px 0;
    position: relative;
    text-decoration: none;
    text-shadow: 0 0 1px rgba(255, 255, 255, 0.5);

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }

    &::after {
      -moz-transform: translateY(10px);
      -moz-transition: opacity 0.3s, -moz-transform 0.3s;
      -webkit-transform: translateY(10px);
      -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
      background: rgba(255, 255, 255, 0.1);
      content: "";
      height: 4px;
      left: 25%;
      opacity: 0;
      position: absolute;
      top: 100%;
      transform: translateY(10px);
      transition: opacity 0.3s, transform 0.3s;
      width: 50%;
    }

    &:hover::after,
    &:focus::after {
      -moz-transform: translateY(0px);
      -webkit-transform: translateY(0px);
      opacity: 1;
      transform: translateY(0px);
    }

    &.isActive::after {
      -moz-transform: translateY(0px);
      -webkit-transform: translateY(0px);
      opacity: 1;
      transform: translateY(0px);
    }
  }
`

export default () => (
  <Navigation>
    <Link to="/mains" activeClassName="isActive">
      mains
    </Link>
    <Link to="/beverages" activeClassName="isActive">
      beverages
    </Link>
    <Link to="/sweets" activeClassName="isActive">
      sweets
    </Link>
    <Link to="/motion" activeClassName="isActive">
      motion
    </Link>
    <Link to="/about" activeClassName="isActive">
      about
    </Link>
    <Link to="/contact" activeClassName="isActive">
      contact
    </Link>
  </Navigation>
)
