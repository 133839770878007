import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import logo from "../../static/logo-v2.png"

const Toolbar = styled.header`
  align-items: center;
  background: #1a1a1a;
  box-shadow: 0 0 4px 2px #262626;
  justify-content: space-between;
  padding: 15px;
  position: fixed;
  width: 100%;
  z-index: 3;

  ${props => (props.isMobile ? "display: flex" : "display: none")}
`

const ToolbarBrand = styled(Link)`
  align-items: center;
  display: flex;
  font-family: "Fugaz", sans-serif;
  text-align: center;
  text-decoration: none;

  > h1 {
    color: #ffffff;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 0;
  }
`

const ToolbarBrandLogo = styled.img`
  margin-bottom: 0;
  margin-right: 10px;
  width: 40px;
`

export default props => (
  <Toolbar isMobile={props.isMobile}>
    <ToolbarBrand to="/">
      <ToolbarBrandLogo src={logo} alt="" />
      <h1>
        Drew Aichele <br />
        Food Stylist
      </h1>
    </ToolbarBrand>
    <button
      aria-label="Menu"
      className={
        "hamburger hamburger--collapse " +
        (props.isSidebarOpen ? "hamburger--isActive" : "")
      }
      type="button"
      onClick={props.toggleSidebar}
    >
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </button>
  </Toolbar>
)
