import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Navigation from "./navigation"
import Logo from "./logo"

const SidebarContainer = styled.div`
  align-items: center;
  background: #1a1a1a;
  box-shadow: 2px 0px 2px 0px #262626;
  flex-flow: column;
  flex: 0 0 40%;
  max-width: 325px;
  padding: 40px 20px;
  z-index: 2;

  ${props => (props.isVisible ? "display: flex" : "display: none")}
`

const SidebarContent = styled.div`
  position: fixed;
`

const SidebarBrand = styled(Link)`
  font-family: "Fugaz", sans-serif;
  text-align: center;
  margin-bottom: 40px;

  ${props => (props.isVisible ? "display: block" : "display: none")}
`

const SidebarTitle = styled.h1`
  color: white;
  font-size: 24px;
  margin: 0;
`

export default props => {
  return (
    <SidebarContainer isVisible={!props.isMobile || props.isSidebarOpen}>
      <SidebarContent>
        <SidebarBrand
          to="/"
          style={{ textDecoration: `none` }}
          isVisible={!props.isMobile}
        >
          <Logo />
          <SidebarTitle>
            Drew Aichele <br />
            Food Stylist
          </SidebarTitle>
        </SidebarBrand>
        <Navigation />
      </SidebarContent>
    </SidebarContainer>
  )
}
